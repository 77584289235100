<template>
  <div class="main-content mt-3 row">
    <div
      class="col-xl-6 col-lg-12 pt-md-3 pb-md-3 pl-md-0 pr-md-0"
      id="videoCol"
    >
      <div class="roomTitle">
        <span v-if="call.loading"> Loading... {{ room.callRoomName }}</span>
        <span v-else-if="!call.loading && room.callRoomName">
          Connected to {{ room.callRoomName }}</span
        >
      </div>
      <div class="video-container">
        <div id="remoteTrack" class="fullscreen-video"></div>
        <!-- <div class="video-container">
        <div
          id="remoteTrack"
          @click="swapScreen()"
          :class="[
            controls.remoteThumbnail
              ? 'thumbnail-video-screen'
              : 'fullscreen-video',
          ]"
        ></div>
        <div
          id="localTrack"
          :class="[
            controls.remoteThumbnail
              ? 'fullscreen-video'
              : ' thumbnail-video-screen',
          ]"
          @click="swapScreen()"
        ></div> -->
      </div>
      <div class="row">
        <div class="d-flex align-items-center justify-content-center">
          <div class="video-control-bar" ref="mainScreen">
            <ul
              class="d-flex flex-row justify-content-start call-participants"
              id="participant-list"
            ></ul>
            <ul class="d-flex justify-content-center call-controls">
              <li>
                <a
                  :class="[
                    controls.screen
                      ? 'video-chat-controls-muted-audio'
                      : 'video-chat-controls',
                  ]"
                  @click="screenShare"
                  role="button"
                  ><i class="bi bi-cast"></i
                ></a>
              </li>
              <li>
                <a
                  :class="[
                    controls.audio
                      ? 'video-chat-controls'
                      : 'video-chat-controls-muted-audio',
                  ]"
                  @click="muteAudio"
                  role="button"
                  ><i
                    :class="[controls.audio ? 'bi-mic' : 'bi-mic-mute', 'bi']"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  :class="[
                    controls.video
                      ? 'video-chat-controls'
                      : 'video-chat-controls-muted-video',
                  ]"
                  @click="muteVideo"
                  role="button"
                  ><i
                    :class="[
                      controls.video
                        ? 'bi-camera-video'
                        : 'bi-camera-video-off',
                      'bi',
                    ]"
                    role="img"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  class="video-chat-controls"
                  data-toggle="modal"
                  data-target="#remarksModal"
                  style="text-decoration: none"
                  @click="loadParticipants"
                  role="button"
                >
                  <i class="bi bi-person-plus"></i>
                </a>
              </li>
              <li>
                <a
                  class="video-chat-controls-leave"
                  data-toggle="modal"
                  data-target="#remarksModal"
                  style="text-decoration: none"
                  @click="leaveRoom"
                  role="button"
                  ><i class="bi bi-x-lg"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Remote Avatar -->
      <span
        v-if="call.remoteVideoMute == true"
        :class="[
          controls.remoteThumbnail ? 'thumbnail-room-avatar' : 'room-avatar',
        ]"
      >
        {{ room?.callRoomName?.substring(0, 2) }}
      </span>
      <!-- Remote Mute Indicator -->
      <div
        :class="[
          controls.remoteThumbnail
            ? 'thumbnail-mute-indicator'
            : ' mute-indicator',
        ]"
        v-if="call.remoteAudioMute || call.remoteVideoMute"
      >
        <i class="bi bi-mic-mute-fill" v-if="call.remoteAudioMute"></i>
        <i class="bi bi-camera-video-off" v-if="call.remoteVideoMute"></i>
      </div>
      <!-- Local Mute Indicator -->
      <div
        :class="[
          controls.remoteThumbnail
            ? 'mute-indicator'
            : ' thumbnail-mute-indicator',
        ]"
        v-if="!controls.audio || !controls.video"
      >
        <i class="bi bi-mic-mute-fill" v-if="!controls.audio"></i>
        <i class="bi bi-camera-video-off" v-if="!controls.video"></i>
      </div>
    </div>
    <div
      class="col-xl-3 col-lg-6 col-md-6 pl-md-0 pb-md-2 p-lg-3 pr-lg-0 pb-xl-0"
    >
      <message-list-component
        :userName="room.callRoomName"
        :userId="room.userId"
      ></message-list-component>
      <div class="pt-3 box-shadow-1 chat-input-area">
        <sent-message-component
          :userId="room.userId"
          from="room"
        ></sent-message-component>
      </div>
    </div>
    <div
      class="col-xl-3 col-lg-6 col-md-6 pr-md-0 pb-md-2 pt-lg-3 pl-lg-0 pb-xl-0"
    >
      <user-profile-component :userId="room.userId"></user-profile-component>
    </div>
    <div
      class="modal fade"
      id="remarksModal"
      tabindex="-1"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Remarks</h5>
            <button
              type="button"
              class="close"
              @click.prevent="cancelRemark()"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3 form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :true-value="1"
                :false-value="0"
                id="flexCheckDefault"
                v-model="remarksForm.is_lead"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Lead Call
              </label>
            </div>
            <div class="mb-3">
              <label for="brandName" class="form-label">Description</label>
              <textarea
                type="text"
                class="form-control"
                id="brandName"
                v-model="remarksForm.comment"
              >
              </textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click.prevent="cancelRemark()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="submitRemark()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List -->
    <div
      class="modal fade"
      id="agentModel"
      tabindex="-1"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Agent</h5>
            <button
              type="button"
              class="close"
              @click.prevent="closeAgentModel"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group">
              <li
                v-for="agent in agentList"
                :key="agent.id"
                class="list-group-item list-group-item-action"
                @click="
                  () => {
                    addParticipant(agent.id);
                  }
                "
              >
                {{ agent.name }}
              </li>
              <li v-if="agentAddError.length > 0">
                <span class="text-danger">{{ agentAddError }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <LastViewedProductsModalComponent :user_id="room.userId" />
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SentMessageComponent from "../components/SentMessageComponent.vue";
import MessageListComponent from "../components/MessageListComponent.vue";
import UserProfileComponent from "../components/UserProfileComponent.vue";
import LastViewedProductsModalComponent from "../components/LastViewedProductsModalComponent.vue";
import {
  LocalParticipant,
  ParticipantEvent,
  Room,
  RoomEvent,
  Track,
} from "livekit-client";

export default {
  components: {
    SentMessageComponent,
    MessageListComponent,
    UserProfileComponent,
    LastViewedProductsModalComponent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const call = reactive({
      loading: false,
      localTrack: false,
      localTracks: "",
      remoteAudioMute: true,
      remoteVideoMute: true,
    });
    const room = reactive({
      callId: store.getters["call/chatId"],
      callRoom: store.getters["call/room"],
      callRoomName: store.getters["call/name"],
      websocket: store.getters["call/websocket"],
      callToken: store.getters["call/accessToken"],
      userId: store.getters["call/userId"],
      mode: store.getters["call/mode"],
    });

    var activeRoom = "";
    const controls = reactive({
      audio: true,
      video: true,
      screen: false,
      remoteThumbnail: false,
    });

    const remarksForm = ref({
      is_lead: 0,
      comment: "",
    });

    const hasRemoteVideo = ref(false);

    const remarksCheck = store.getters["auth/getRemarkCheck"];

    const remarksModal = ref();

    const mainScreen = ref(null);

    const agentModel = ref();
    const agentList = ref([]);
    const agentAddError = ref("");

    let localViewCreated = false;

    var node = document.createElement("div");
    node.setAttribute("id", "mutedContainer");
    node.setAttribute("class", "muted-container");
    const text = document.createTextNode(localStorage.getItem("callerName"));
    node.appendChild(text);

    function onCallDetails() {
      // axios
      //   .post(
      //     process.env.VUE_APP_POPIN_URL + "/api/v1/seller/connection/details",
      //     {
      //       connect_request_id: room.callId,
      //     }
      //   )
      //   .then((res) => {
      //     console.log("call details response ", res);
      //     room.callRoom = res.data.room;
      //     room.callRoomName = res.data.user_name;
      //     room.callToken = res.data.access_token;
      //     room.websocket = res.data.websocket;
      startVideo();
      // });
    }

    async function loadParticipants() {
      await axios
        .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/agents")
        .then(async (res) => {
          agentList.value = res.data;
          await nextTick();
          agentModel.value.show();
        });
    }

    function closeAgentModel() {
      agentModel.value.hide();
    }

    async function addParticipant(agentID) {
      await axios
        .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/participant", {
          call_id: room.callId,
          seller_id: agentID,
        })
        .then(async (res) => {
          if (res.data.status == 1) {
            agentModel.value.hide();
            agentAddError.value = "";
          } else {
            agentAddError.value = res.data.message;
          }
        });
    }

    async function startVideo() {
      call.loading = true;
      // before a user enters a new room,
      // disconnect the user from they joined already
      // remove any remote track when joining a new room
      document.getElementById("remoteTrack").innerHTML = "";
      // if local preview is not active, create it
      if (!call.localTrack) {
        activeRoom = new Room({
          adaptiveStream: true,
          dynacast: true,
        });

        activeRoom.prepareConnection(room.websocket, room.callToken);
        setupListeners(activeRoom);
        await activeRoom.connect(room.websocket, room.callToken);

        activeRoom.localParticipant.enableCameraAndMicrophone().then(() => {
          controls.audio = true;
          controls.video = true;
        });

        call.loading = false;

        activeRoom.remoteParticipants.forEach((participant) => {
          handleParticipantConnected(participant);
        });
      }
    }

    async function screenShare() {
      if (!activeRoom) return;
      controls.screen = !controls.screen;
      const enabled = activeRoom.localParticipant.isScreenShareEnabled;
      try {
        await activeRoom.localParticipant.setScreenShareEnabled(!enabled, {
          audio: true,
        });
      } catch (e) {
        console.log("ERR");
      }
    }

    function swapScreen() {
      if (controls.remoteThumbnail) {
        controls.remoteThumbnail = false;
      } else {
        controls.remoteThumbnail = true;
      }
    }

    function submitRemark() {
      console.log("remarks form", remarksForm);
      axios
        .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/call/remark", {
          call_id: room.callId,
          lead: remarksForm.value.is_lead,
          remark: remarksForm.value.comment,
        })
        .then((res) => {
          console.log("Remarks update", res.data);
        });

      setTimeout(() => {
        remarksModal.value.hide();
        console.log("timeout");
        store.dispatch("call/resetCall").then(() => {
          router.push({ name: "messages" });
        });
        // window.location.reload();
      }, 1000);
    }

    function cancelRemark() {
      setTimeout(() => {
        remarksModal.value.hide();
        console.log("timeout");
        store.dispatch("call/resetCall").then(() => {
          router.push({ name: "messages" });
        });
        // window.location.reload();
      }, 100);
    }

    function setupListeners() {
      activeRoom
        .on(RoomEvent.ParticipantConnected, handleParticipantConnected)
        .on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected)
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.Disconnected, handleRoomDisconnected)
        .on(RoomEvent.LocalTrackPublished, handleLocalTrackPublished)
        .on(RoomEvent.TrackMuted, handleTrackMuted)
        .on(RoomEvent.TrackUnmuted, handleTrackUnmuted);
    }

    async function handleParticipantConnected(participant) {
      // participant.trackPublications.forEach((track) => {
      //   track.setVideoQuality(VideoQuality.HIGH)
      // })
      participant
        .on(ParticipantEvent.TrackMuted, () => {
          renderParticipant(participant);
          // attachParticipantVideoToList(participant);
        })
        .on(ParticipantEvent.TrackUnmuted, () => {
          renderParticipant(participant);
          // attachParticipantVideoToList(participant);
        });
    }

    function handleParticipantDisconnected(participant) {
      renderParticipant(participant, true);
      leaveRoom();
    }

    function handleTrackSubscribed(track, publication, participant) {
      renderParticipant(participant);
      // attachParticipantVideoToList(participant);
    }

    function handleTrackUnsubscribed(track, publication, participant) {
      renderParticipant(participant);
      // attachParticipantVideoToList(participant);
    }

    async function handleRoomDisconnected() {
      activeRoom = null;
    }

    async function handleLocalTrackPublished(pub) {
      if (!localViewCreated) {
        const track = pub.track;
        if (track.kind === Track.Kind.Video) {
          // renderLocalVideo(track);
          renderLocalParticipant();
          localViewCreated = true;
        }
      }
    }

    // function renderLocalVideo(track) {
    //   console.log("RENDER_LOCAL_VIDEO");
    //   const element = track.attach();
    //   const myVideoElm = document.getElementById("localTrack");
    //   if (track) {
    //     myVideoElm.appendChild(element);
    //   }
    // }

    async function setNextActiveVideo() {
      if (activeRoom) {
        let rendered = false;
        activeRoom.remoteParticipants.forEach((participant) => {
          if (!rendered && !(participant instanceof LocalParticipant)) {
            const cameraPub = participant.getTrackPublication(
              Track.Source.Camera
            );
            const cameraEnabled =
              cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
            if (cameraEnabled) {
              window.itemVideoClicked(participant.identity);
              rendered = true;
            }
          }
        });
      }
    }

    window.itemVideoClicked = function (itemName) {
      console.log(
        "item_click>" + itemName + ">" + activeRoom.localParticipant.identity
      );
      // if (activeRoom.localParticipant.identity === itemName) {
      //   console.log("self_CLICK_return");
      //   return
      // }

      if (activeRoom) {
        activeRoom.remoteParticipants.forEach((participant) => {
          const cameraPub = participant.getTrackPublication(
            Track.Source.Camera
          );
          const cameraEnabled =
            cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
          if (cameraEnabled) {
            if (participant.identity === itemName) {
              console.log(
                "found_click>" +
                  itemName +
                  ">" +
                  activeRoom.localParticipant.identity
              );
              document.getElementById("remoteTrack").innerHTML = "";
              let videoElement = document.createElement("video");
              videoElement.id = "main-remote-video";
              document.getElementById("remoteTrack").appendChild(videoElement);
              cameraPub?.videoTrack?.attach(videoElement);
            }
          }
        });
      }
    };

    async function handleTrackMuted(publication, participant) {
      if (
        participant instanceof LocalParticipant === false &&
        publication.track.kind === Track.Kind.Video
      ) {
        // participantVideoDisabled.value = true;
      }
    }

    async function handleTrackUnmuted(publication, participant) {
      if (
        participant instanceof LocalParticipant === false &&
        publication.track.kind === Track.Kind.Video
      ) {
        // participantVideoDisabled.value = false;
      }
    }

    async function renderLocalParticipant() {
      if (!activeRoom) {
        console.log("No active Room");
        return;
      }

      if (activeRoom.localParticipant) {
        renderParticipant(activeRoom.localParticipant);
      }
    }

    // function attachParticipantVideoToList(participant) {
    //   if (!activeRoom) {
    //     console.log("No active Room");
    //     return;
    //   }

    // let participantList = document.getElementById("participant-list");
    // if (!participantList) {
    //   return;
    // }

    // let participantListItem = document.createElement("li");

    // const cameraPub = participant.getTrackPublication(Track.Source.Camera);
    // const cameraEnabled =
    //   cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
    // if (cameraEnabled) {
    //   let videoElement = document.createElement("video");
    //   videoElement.id = `participant-${participant.identity}`;
    //   videoElement.classList = `participant-video`;
    //   videoElement.addEventListener("click", () => {
    //     renderMainVideo(participant);
    //   });
    //   participantListItem.appendChild(videoElement);
    //   participantList.appendChild(participantListItem);

    //   cameraPub?.videoTrack?.attach(videoElement);
    // }
    // }

    // function renderMainVideo(participant) {
    //   renderParticipant(participant);
    // }

    async function renderParticipant(participant, remove = false) {
      console.log("Participant :");
      console.log(participant);
      let container = document.getElementById("participant-list");
      if (!container) return;
      const { identity } = participant;
      if (!identity) return;
      let div = document.getElementById(`participant-${identity}`);
      if (!div && !remove) {
        div = document.createElement("li");

        div.id = `participant-${identity}`;
        div.className = "participant";
        div.setAttribute("onclick", `itemVideoClicked('${identity}')`);
        div.innerHTML = `
      <video id="video-${identity}" class="participant-video"></video>
      <audio id="audio-${identity}"></audio>
    `;
        if (participant instanceof LocalParticipant) {
          container.prepend(div);
        } else {
          container.append(div);
        }
        hasRemoteVideo.value = true;
      }
      const videoElm = document.getElementById(`video-${identity}`);
      const audioELm = document.getElementById(`audio-${identity}`);
      if (remove) {
        hasRemoteVideo.value = false;
        div?.remove();
        if (videoElm) {
          call.remoteVideoMute = true;
          videoElm.srcObject = null;
          videoElm.src = "";
        }
        if (audioELm) {
          call.remoteAudioMute = true;
          audioELm.srcObject = null;
          audioELm.src = "";
        }
        return;
      }
      // update properties

      const cameraPub = participant.getTrackPublication(Track.Source.Camera);
      const micPub = participant.getTrackPublication(Track.Source.Microphone);
      const cameraEnabled =
        cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
      if (cameraEnabled) {
        // if (participant instanceof LocalParticipant) {
        //   // flip
        //   console.log("LOICAL_PARTICI");
        // } else {
        cameraPub?.videoTrack?.attach(videoElm);
        // }
        call.remoteVideoMute = false;
      } else {
        if (
          cameraPub?.videoTrack &&
          !(participant instanceof LocalParticipant)
        ) {
          // detach manually whenever possible
          cameraPub.videoTrack?.detach(videoElm);
        } else {
          videoElm.src = "";
          videoElm.srcObject = null;
        }
        call.remoteVideoMute = true;
      }
      // enableControls.value = true;
      const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
      if (micEnabled) {
        if (!(participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.attach(audioELm);
          // controls.audio = false;
        }
        call.remoteAudioMute = false;
      } else {
        call.remoteAudioMute = true;
      }
      setNextActiveVideo();
    }

    function leaveRoom() {
      if (activeRoom) {
        console.log("leaveroom");
        activeRoom.localParticipant?.tracks?.forEach((publication) => {
          publication?.track?.stop();
          const attachedElements = publication?.track?.detach();
          attachedElements.forEach((element) => element.remove());
        });
        activeRoom.disconnect();
      }
      // document.getElementById("localTrack").innerHTML = "";
      document.getElementById("remoteTrack").innerHTML = "";
      if (remarksCheck == 1) {
        remarksModal.value.show();
        document.getElementById("videoCol").innerHTML = "";
      } else {
        console.log("timeout");
        store.dispatch("call/resetCall").then(() => {
          router.push({ name: "messages" });
        });
        // setTimeout(() => {

        // }, 3000);
      }
    }

    function muteAudio() {
      controls.audio = !controls.audio;
      activeRoom.localParticipant.setMicrophoneEnabled(controls.audio);
    }

    function muteVideo() {
      controls.video = !controls.video;
      activeRoom.localParticipant.setCameraEnabled(controls.video);
      // const myVideoElm = document.getElementById("localTrack");
      // if (controls.video) {
      //   myVideoElm.style.visibility = "hidden";
      // } else {
      //   myVideoElm.style.visibility = "visible";
      // }
    }

    onMounted(() => {
      remarksModal.value = new Modal(document.getElementById("remarksModal"));
      agentModel.value = new Modal(document.getElementById("agentModel"));
    });

    return {
      call,
      room,
      controls,
      mainScreen,
      onCallDetails,
      startVideo,
      muteAudio,
      muteVideo,
      leaveRoom,
      activeRoom,
      swapScreen,
      agentModel,
      agentList,
      agentAddError,
      remarksModal,
      remarksForm,
      submitRemark,
      cancelRemark,
      remarksCheck,
      screenShare,
      loadParticipants,
      addParticipant,
      closeAgentModel,
    };
  },
  mounted() {
    console.log("MOUNTED");
    this.onCallDetails();
    console.log(this.room);
  },
  created() {},
};
</script>

<style>
.form-check {
  display: flex;
  align-items: center;
}

.roomTitle {
  position: absolute;
  top: 12px;
  widows: 100%;
  text-align: center;
}

.video-container {
  position: relative;
  height: calc(100vh - 110px);
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.thumbnail-video-screen {
  position: absolute;
  bottom: 3%;
  right: 0.5%;
  border-radius: 10px;
  width: 212px;
  min-height: 139px;
  background-color: #0e1011;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.thumbnail-video-screen video {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 250px;
  margin: 10px;
  object-fit: contain;
  border-radius: 10px;
  background: #0e1011;
}

.fullscreen-video {
  position: relative;
  width: 100%;
  background: #000;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem !important;
}

/* .fullscreen-video video {
  height: 100%;
  object-fit: cover;
} */
.fullscreen-video video {
  height: 100%;
  width: 100%;
  background: #0e1011;
}

.video-chat-controls {
  background: #fff;
  color: #000;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 21px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.16);
  margin-right: 5px;
}

.video-chat-controls-muted-video {
  background: red;
  color: #fff;
  border-radius: 50%;
  border: 2px solid red;
  font-size: 21px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.16);
  margin-right: 5px;
}

.video-chat-controls-muted-video:hover {
  color: #fff;
}

.video-chat-controls-muted-audio {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid red;
  background: red;
  color: #fff;
  border-radius: 50%;
  border: 2px solid red;
  font-size: 21px;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.16);
  margin-right: 5px;
}

.video-chat-controls-muted-audio:hover {
  color: #fff;
}

.video-chat-controls-leave {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: #fff;
  border-radius: 50%;
  border: 2px solid red;
  font-size: 21px;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.16);
}

.video-chat-controls-leave:hover {
  color: #fff;
}

.video-chat-controls:hover {
  color: #000;
  background: #fff;
}

.video-control-bar .call-controls {
  margin: 0;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.video-control-bar .call-participants {
  margin: 0;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: 96px;
}

.video-control-bar .agent-in-call {
  position: inherit;
  width: 100%;
  top: 50%;
  color: #fff;
}

.video-control-bar {
  position: absolute;
  width: 100%;
  bottom: 12px;
  height: 100%;
}

.video-control-bar ul > li {
  display: inline-block;
  zoom: 1;
  display: inline;
  margin: 4px;
}

.thumbnail-video-screen .muted-container {
  height: 50px;
  width: 50px;
  font-size: 22px;
}

.fullscreen-video .muted-container {
  height: 100px;
  width: 100px;
  font-size: 30px;
}

.muted-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  text-align: center;
  background: #342aa8;
  border-radius: 50%;
}

.fullscreen-video .mute-container {
  height: 50px;
  width: 50px;
  font-size: 25px;
}

.mute-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  text-align: center;
  background: rgb(125, 134, 145);
  border-radius: 50%;
  z-index: 100;
}

#remoteTrack .participant {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mute-indicator,
.thumbnail-mute-indicator {
  position: absolute;
  color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.mute-indicator {
  top: 32px;
  left: 16px;
}

.thumbnail-mute-indicator {
  bottom: 5%;
  right: 2%;
}

.room-avatar,
.thumbnail-room-avatar {
  position: absolute;
  text-transform: uppercase;
  color: #fff;
  background-color: #342aa8;
  text-align: center;
  border-radius: 50%;
  pointer-events: none;
  z-index: 100;
}

.room-avatar {
  right: 50%;
  bottom: 50%;
  font-size: 48px;
  line-height: 56px;
  padding: 28px 24px;
  width: 112px;
  height: 112px;
  transform: translate(50%, 50%);
}

.thumbnail-room-avatar {
  bottom: calc(3% + 92px);
  right: calc(0.5% + 116px);
  font-size: 24px;
  line-height: 36px;
  padding: 12px;
  width: 64px;
  height: 64px;
  transform: translate(50%, 50%);
}

.participant-video {
  height: 80px !important;
  object-fit: contain;
  border-radius: 6px;
}
</style>
