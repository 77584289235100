<template>
  <div class="card border-0 rounded-card cards p-2">
    <div class="d-flex pb-24 pt-12 border-bottom m-24 mt-2 chat-topbar">
      <a class="link-icon d-md-none" data-sidebar-toggle="chat"
        ><i class="icon-regular i-Right ml-0 mr-3"></i
      ></a>
      <div class="d-flex align-items-center">
        <div
          class="avatar text-light text-11 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase"
        >
          {{ userName.substring(0, 2) }}
        </div>
        <div class="mb-0 mt-0 mr-0 text-title font-weight-600 font-inter ml-16">
          <p class="m-0 text-14">{{ userName }}</p>
          <p class="m-0 text-12 font-weight-500">{{ mobile ?? "-" }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex flex-column align-items-center">
      <div class="avatar-2 text-light text-11 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase">{{ userName.substring(0, 2) }}</div>
      <div class="font-inter font-weight-600 text-17">{{ userName }}</div>
      <div class="font-inter font-weight-400 text-13">
        +91 929394955
      </div>
      <div class="font-Inter font-weight-400 text-13">
        advsff@gmail.com
      </div>
    </div>
    <div class="brs-2 my-48"></div> -->
    <!-- <div
      class="chat-content perfect-scrollbar pt-2 px-2 mt-16"
      id="chatContainer"
      style="overflow-y: auto"
    > -->
    <!-- min-height: 525px" -->
    <!-- height: 525px -->
    <div
      class="chat-content perfect-scrollbar pt-2 px-2"
      id="chatContainer"
      style="overflow-y: auto"
    >
      <div class="d-flex mb-4" v-if="!loader && userMessages.length == 0">
        <div
          class="flex-grow-1 border-0 font-inter font-weight-400 text-12 text-center"
        >
          <p class="m-0">No messages yet !</p>
        </div>
      </div>
      <div class="text-center" v-if="loader">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <template v-else v-for="msg in userMessages" :key="msg.message_id">
        <div
          class="d-flex mb-4 text-end to-user justify-content-end text-start"
          v-if="msg.direction == 1"
        >
          <div
            class="message-user d-flex bg-chat card flex-row align-items-center"
            v-if="msg.type == '7'"
            style="word-break: break-all"
          >
            <a :href="msg.data.url" target="_blank">
              <img
                :src="msg.data.image"
                width="90"
                height="90"
                class="rounded-2"
              />
            </a>
            <span class="ml-16">{{ msg.data.name }}</span>
          </div>
          <div
            class="message-user border-0 font-inter font-weight-400 text-12 card justify-content-end chat-view"
            v-else
            style="word-break: break-all"
          >
            <!-- <div class="d-flex">
              <p class="mb-1 text-title text-16 flex-grow-1">
                {{ msg.agent_name }}
              </p> -->
            <!-- <span class="text-small text-muted">25 min ago</span> -->
            <!-- </div> -->
            <template v-if="msg.data.image">
              <a :href="msg.data.image" target="_blank"> {{ msg.data.image }}</a
              ><br />
            </template>
            <template v-if="msg.data.file">
              <a :href="msg.data.file" target="_blank">{{ msg.data.file }}</a
              ><br />
            </template>
            <p
              class="m-0 text-start word-break"
              v-html="urlify(msg.data.text)"
            ></p>
          </div>
        </div>
        <div class="d-flex mb-4 user" v-else>
          <div class="message border-0 font-inter font-weight-400 text-12 card">
            <div class="d-flex chat-view">
              <!-- <span class="text-small text-muted">24 min ago</span> -->
            </div>
            <template v-if="msg.data.image">
              <a :href="msg.data.image" target="_blank"> {{ msg.data.image }}</a
              ><br />
            </template>
            <template v-if="msg.data.file">
              <a :href="msg.data.file" target="_blank"> {{ msg.data.file }}</a
              ><br />
            </template>
            <p
              class="m-0 text-start word-break"
              v-html="urlify(msg.data.text)"
            ></p>
          </div>
        </div>
      </template>
      <div class="d-flex mb-4" v-if="fileUpload">
        <div class="message card">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="'width: ' + progressValue + '%'"
              :aria-valuenow="progressValue"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <i class="bi bi-person text-30" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { nextTick, onMounted } from "vue";
import emitter from "tiny-emitter/instance";
import { urlify } from "../helpers/string";

export default {
  props: ["userName", "userId", "mobile"],
  setup(props) {
    const userMessages = ref([]);
    const fileUpload = ref(false);
    const progressValue = ref(0);
    const loader = ref(false);

    function scrollDown() {
      nextTick(() => {
        var chatElement = document.getElementById("chatContainer");
        let updateheight = parseInt(chatElement.offsetHeight) + parseInt(10000);
        chatElement.scrollTop = updateheight;
      });
    }

    function getMessages() {
      loader.value = true;
      axios
        .get(
          process.env.VUE_APP_POPIN_URL +
            "/api/v1/seller/user/" +
            props.userId +
            "/messages"
        )
        .then((res) => {
          if (res.data.status == 1) {
            userMessages.value = res.data.messages;
            setTimeout(() => {
              scrollDown();
            }, 500);
          }
        })
        .finally(() => {
          loader.value = false;
        });
    }

    onMounted(() => {
      emitter.on("sellerMsg", (data) => {
        userMessages.value.push(data);
        scrollDown();
      });
      emitter.on("newMessage", (data) => {
        userMessages.value.push(data.message);
        scrollDown();
      });
      emitter.on("uploadPercent", (data) => {
        fileUpload.value = data[1];
        if (fileUpload.value) {
          scrollDown();
        }
        progressValue.value = data[0];
        if (progressValue.value == 100) {
          fileUpload.value = false;
        }
      });
      getMessages();
    });

    return {
      userMessages,
      getMessages,
      urlify,
      fileUpload,
      progressValue,
      loader,
    };
  },
  watch: {
    userName() {
      if (this.userId) {
        this.getMessages();
      }
    },
  },
};
</script>

<style scoped>
.cards {
  /* max-height: calc(81.5vh - 120px) !important; */
  max-height: calc(86.5vh - 115px) !important;
  /* max-height: 685px; */
}

#chatContainer {
  height: calc(95vh - 353px);
}

.rounded-card {
  border-radius: 10px !important;
}

.avatar-2 {
  width: 50px;
  height: 50px;
  background: #710ec5;
}

.brs-2 {
  margin: 0 auto;
  width: 33%;
  border-bottom: 1px solid lightgrey;
}

.chat-content {
  /* height: calc(67vh - 350px); */
  height: calc(99vh - 350px);
}

.chat-topbar {
  height: 7vh;
}

.message {
  width: fit-content;
  margin-left: 10px;
  margin-right: 10px;
}

.message-user {
  width: fit-content;
  margin-left: 40px;
  margin-right: 10px;
}

.to-user {
  width: fit-content;
  margin-left: auto;
}

.word-break {
  word-break: break-all;
}

.chat-view {
  min-width: 58%;
}
</style>
