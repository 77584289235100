<template>
  <div class="main-content pt-4">
    <div class="row">
      <div class="col-6">
        <div class="card border-0">
          <div class="card-body m-3" style="height: 100%; overflow-y: auto; scrollbar-width: thin;">
            <div class="d-flex mb-35 justify-content-center">
              <div id="circle">
                <img class="profile-img" :src="profile.image_url" width="100" />
              </div>
            </div>
            <div class="mb-35 ml-36 d-flex justify-content-center align-items-center">
              <div class="font-inter pr-28 font-weight-600 text-20">{{ profile.agent_name }}</div>
              <span><i class="bi bi-pencil-square text-20 text-purple cursor-pointer" data-bs-toggle="modal" data-bs-target="#profileUpdateModal"></i></span>
            </div>
            <div class="font-inter pb-24 text-16 font-weight-400 text-center">
              Description Description
            </div>
            <div class="d-flex mb-60 justify-content-center">
              <span class="text-inter text-16 font-weight-500 text-purple text-decoration-underline">{{
                profile.url
              }}
              </span>
            </div>
            <div class="border-bottom-dark"></div>
            <div class="d-flex justify-content-evenly">
              <div class="mt-50 text-center">
                <div>
                  <span class="font-inter font-weight-500 text-16 mt-14">Total Sales <div>&nbsp;</div></span>
                </div>
                <div class="text-20 font-inter mb-12 text-center font-weight-600 text-purple mt-50">
                  ₹ {{ profile.total_sales }}
                </div>
              </div>
              <span class="border-stret mt-50"></span>
              <div class="mt-50">
                <div class="w-75 m-auto text-center">
                  <span class="font-inter font-weight-500 text-16 mt-12">Connection Requests</span>
                </div>
                <div class="text-20 font-inter mb-12 text-center font-weight-600 text-purple mt-50">
                  {{ profile.total_connections }}
                </div>
              </div>
              <span class="border-stret mt-50"></span>
              <div class="mt-50">
                <div class="text-center m-auto text-center">
                  <span class="font-inter font-weight-500 text-16 mt-12">Call Acceptance <div>Rate</div></span>
                </div>
                <div class="text-20 font-inter mb-12 text-center font-weight-600 text-purple mt-50">
                  {{ profile.call_acceptance }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly">
              <div class="mt-50 text-center">
                <div>
                  <span class="font-inter font-weight-500 text-16 mt-14">Total Video Calls</span>
                </div>
                <div class="text-20 font-inter mb-12 text-center font-weight-600 text-purple mt-50">
                  {{ profile.total_calls }}
                </div>
              </div>
              <span class="border-stret mt-50"></span>
              <div class="mt-50">
                <div class="text-center">
                  <span class="font-inter font-weight-500 text-16 mt-14">Avg. Call Duration</span>
                </div>
                <div class="text-20 font-inter mb-12 text-center font-weight-600 text-purple mt-50">
                  {{ profile.average_duration }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card border-0">
          <div class="card-body m-3" style="height: 100%; overflow-y: auto; scrollbar-width: thin;">
            <div class="font-inter font-weight-600 text-20 text-center mt-16">Reviews & Ratings</div>
            <div class="d-flex justify-content-center my-5">
              <star-rating
                v-model:rating="profile.rating"
                :increment="0.01"
                :show-rating="false"
                active-color="#710EC5"
                read-only
              />
            </div>
            <div class="mt-36 text-center mb-60">(4300 Ratings)</div>
            <div class="border-bottom-dark"></div>
            <div class="m-4">
              <div class="row" v-for="review in reviews" :key="review.id">
                <div class="row row-cols-auto">
                  <h6 class="col mb-0 mt-1">
                    {{ review.name }}
                  </h6>
                  <star-rating
                    class="col pb-2"
                    v-model:rating="review.rating"
                    :show-rating="false"
                    :star-size="20"
                    active-color="#710EC5"
                    read-only
                  />
                </div>
                <div v-if="review.comments" class="comments mb-40">
                  {{ review.comments }}
                </div>
                <div v-else class="comments mb-40">
                  No Comments
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of main-content -->

    <div class="modal fade" id="profileUpdateModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Profile</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <label for="brandName" class="col-sm-4 col-form-label"
                >Name</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="brandName"
                  v-model="profileForm.agent_name"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="brandName" class="col-sm-4 col-form-label"
                >Description</label
              >
              <div class="col-sm-8">
                <textarea
                  type="text"
                  class="form-control"
                  id="brandName"
                  v-model="profileForm.description"
                >
                </textarea>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="profileImage" class="col-sm-4 col-form-label"
                >Profie Image</label
              >
              <div class="col-sm-8">
                <input
                  type="file"
                  class="form-control"
                  id="profileImage"
                  ref="uploadButton"
                  accept="image/png, image/jpeg"
                  @change="handleFileUpload()"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="updateProfile()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import StarRating from "vue-star-rating";
import { useToast } from "vue-toastification";
import { Modal } from "bootstrap";

const toast = useToast();
const profileUpdateModal = ref();

const profile = reactive({
  url: "",
  name: "",
  agent_name: "",
  description: "",
  image_url: "",
  image: "",
  rating: 0,
  total_sales: "",
  total_connections: 0,
  total_calls: "",
  average_duration: "",
  call_acceptance: "",
});

const profileForm = reactive({
  agent_name: "",
  description: "",
  image: "",
});

const uploadButton = ref(null);

const reviews = ref({});

onMounted(() => {
  getProfile();
  profileUpdateModal.value = new Modal(
    document.getElementById("profileUpdateModal")
  );
});

function getProfile() {
  axios
    .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/profile")
    .then((res) => {
      profile.name = res.data.name;
      profile.agent_name = res.data.agent_name;
      profile.description = res.data.description;
      profile.image_url = res.data.image;
      profile.rating = Math.round(res.data.average_rating * 100) / 100;
      profile.total_sales = res.data.currency + res.data.total_sales;
      profile.total_connections = res.data.total_connections;
      profile.average_duration = res.data.average_duration + "s";
      profile.total_calls = res.data.total_calls;
      profile.call_acceptance =
        Math.round((res.data.accepted_calls / res.data.total_calls) * 100) +
        "%";
      profile.url = res.data.url;
      getRatings();
      profileForm.agent_name = profile.agent_name;
      profileForm.description = profile.description;
    });
}

function getRatings() {
  axios
    .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/ratings")
    .then((res) => {
      console.log(res.data);
      reviews.value = res.data;
    });
}

function updateProfile() {
  axios
    .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/profile/update", {
      name: profile.name,
      agent_name: profileForm.agent_name,
      description: profileForm.description,
    })
    .then((res) => {
      if (res.data.status == 1) {
        profileUpdateModal.value.hide();
        toast.success("Profile updated successfully");
        const formdata = new FormData();
        formdata.append("image", profileForm.image);
        axios
          .post(
            process.env.VUE_APP_POPIN_URL +
              "/api/v1/seller/profile/image/update",
            formdata
          )
          .then((resp) => {
            if (resp.data.status == 1) {
              toast.success("Profile image updated successfully");
            }
          })
          .catch(() => {
            toast.error("Profile image update failed");
          });
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
}

function handleFileUpload() {
  profileForm.image = uploadButton.value.files[0];
}
</script>
<style scoped>
.border-stret{
  border-right: 0.25px solid #000;
}
.profile-img {
  border-radius: 50%;
}

.card {
  height: calc(100vh);
}
#circle {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

#circle img {
  /* margin-top: 7px; */
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #ececec;
}
::-webkit-scrollbar-thumb {
  background: #663399;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(102, 51, 150, 0.75);
}

::-webkit-scrollbar-corner {
  background: #663399;
}
</style>
